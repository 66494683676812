.welcome-console {
	position: absolute;
	top: 0;
	left: 0;
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	width: 100vw;
	height: 100vh;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.welcome-wrapper {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.image-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
    animation: pulse 5s;
    animation-iteration-count: infinite;
}

.image-border {
	border: 4px solid white;
	border-radius: 50%;
	box-shadow: 0px 0px 20px white;
}

.image-shadow {
	border-radius: 50%;
	border-color: linear-gradient(-45deg, #da3807, #c40951, #0294c9, #02a780);
	animation: welcomePicShadow 15s ease infinite;
}

.image-wrapper img {
	width: 100%;
	height: 100%;
	max-width: 300px;
	max-height: 300px;
	cursor: pointer;
}


@keyframes welcomePicShadow {
    0% {
        box-shadow: 10px 0px 100px 20px #da3807;
    }

    35% {
        box-shadow: 15px 0px 150px 20px #c40951;
    }

	70% {
        box-shadow: 0px 10px 50px 15px #0294c9;
    }

    100% {
        box-shadow: 0px 15px 100px 20px #02a780;
    }
}

@keyframes pulse {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(15px);
    }

    100% {
        transform: translateY(0px);
    }
}


.title {
	text-align: center;
	padding-top: 6%;
	font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
	font-size: 1.5em;
	color: transparent;
	text-shadow: 0px 0px 2px whitesmoke;
	-webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: black;
}