.nav-console {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 5vh;
    z-index: 999;
}

.nav-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 6vh;
    background-color: black;
    opacity: 80%;
    z-index: 997;
}

.nav-wrapper ul {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style-type: none;
    color: whitesmoke;
    font-family: 'Roboto Condensed', sans-serif;
}

.nav-wrapper li {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    line-break: strict;
    word-wrap: unset;
    cursor: pointer;
}

.nav-wrapper li:hover {
  color: white;
  font-weight: bolder;
}

.nav-wrapper h1 {
    padding-left: 1%;
    width: 30%;
    color: whitesmoke;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    font-size: 1.5em;
    cursor: pointer;
}

.nav-wrapper i {
    color: white;
    font-size: 1.5em;
    visibility: hidden;
    padding-right: 5%;
    cursor: pointer;
}

@keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes slideIn {
    0% {
      transform: translateX(-2%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes shrink {
    0% {
      width: 95%;
    }
    100% {
      width: 90%;
    }
  }

/*====MOBILE====*/
@media only screen and (max-width: 950px) { 
    .nav-wrapper i {
        visibility: visible;
    }
    
    .nav-wrapper h1 {
        width: 100%;
        font-size: 1.5em;
    }


    .menuNav {
        overflow-y: scroll;
        list-style: none;
        position: fixed;
        top: 3vh;
        background: rgb(19, 19, 19);
        right: 0;
        bottom: 0;
        height: 7vh;
        width: 0;
        overflow: hidden;
        z-index: 0;
      }

      .menuNav.showMenu {
        width: 100vw;
      }

      .menuNav.hidden{
          display: none;
      }
}
