/*Process Console Styling and Formatting*/
.process-console {
    position: absolute;
    top: 300vh;
    left: 0;
    padding-right: 20px;
    width: 100vw;
    height: 70vh;
}

.process-header {
    padding-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
	font-size: 2.5em;
	background-image: -webkit-linear-gradient(92deg, #f35626, #feab3a);
	animation: hue 5s infinite linear;
    -webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	-webkit-animation: hue 10s infinite linear;
    color: white;
    
}

@keyframes hue {
    from {
        -webkit-filter: hue-rotate(0deg);
      }
    
      to {
        -webkit-filter: hue-rotate(-360deg);
      }
}

@-webkit-keyframes hue {
  from {
    -webkit-filter: hue-rotate(0deg);
  }

  to {
    -webkit-filter: hue-rotate(-360deg);
  }
}

.process-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

/*Steps Process and Styling*/
.step-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.process-circle {
    width: 175px;
    height: 175px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -15px;
}

.process-image {
    width: 100%;
    height: 100%;
    z-index: 200;
}

.process-text {
    display: flex;
    text-align: center;
    flex-direction: column;
    margin-top: -10px;
}

.process-name, .process-desc {
    font-family: 'Roboto Condensed', sans-serif;
    color: whitesmoke;
}

.process-name {
    font-weight: bolder;
    font-size: 1.5em;
    color: whitesmoke;
}

.process-desc {
    font-size: 1em;
    margin-top: -10px;
}

.static, .active {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 150px;
    max-height: 150px;
}

.active {
    opacity: 0;
}

.process-circle:hover .active {
    opacity: 1;
}

.process-circle:hover .static {
    opacity: 0;
}


.circle-1 {
    background-color: #FAACA8;
    background-image: linear-gradient(19deg, #FAACA8 0%, #DDD6F3 100%); 
    box-shadow: 0 0 20px #ff4e45;
}

.circle-2 {
    background-image: radial-gradient( circle 448px at 88.2% 27%,  rgba(247,132,189,1) 73.4%, rgba(187,187,187,1) 100.2% );
    box-shadow: 0 0 20px rgb(255, 0, 128);
}

.circle-3 {
    background-color: #FAD961;
    background-image: linear-gradient(90deg, #FAD961 0%, #F76B1C 100%);
    box-shadow: 0 0 20px #ffc800;
}

.circle-4 {
    background-color: #85FFBD;
    background-image: linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%);
    box-shadow: 0 0 20px #00ff77;
}

.circle-5 {
    background-color: #8EC5FC;
    background-image: linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);
    box-shadow: 0 0 20px #0080ff;

}


/*====MOBILE====*/
@media only screen and (max-width: 1200px) { 
    .process-console {
        top: 450vh;
        height: 100vh;
    }
}

@media only screen and (max-width: 950px) { 
    .process-console {
        top: 430vh;
    }

    .process-container {
        flex-direction: column;
        row-gap: 20px;
    }
}
