.contact-console {
    position: absolute;
    top: 500vh;
    left: 0;
    width: 100vw;
    height: 100vh;
    box-shadow: 0 0 10px whitesmoke;
    background-color: #8EC5FC;
    background-image: linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);
}

.contact-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 100%;
    width: 100%;
}

.contact-wrapper h1 {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
	font-size: 5em;
	color: transparent;
	text-shadow: 0px 0px 2px whitesmoke;
	-webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: black;
}


form {
    font-family: 'Roboto Condensed', sans-serif;
    padding: 0 35% 0 35%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
}

label {
    font-weight: bold;
    font-size: 1.2em;
}

input {
    border-radius: 10px;
    background-color: whitesmoke;
    height: 5vh;
    width: 20vw;
    font-family: 'Roboto Condensed', sans-serif;
}

textarea {
    font-family: 'Roboto Condensed', sans-serif;
    height: 15vh;
    width: 20vw;
    border-radius: 10px;
}


button {
    height: 2em;
    width: 7em;
    border-radius: 25px;
    text-align: center;
}

button:hover {
    color: whitesmoke;
    background-color: black;
    box-shadow: 1px 1px 3px whitesmoke;
}


span .visible {
    visibility: visible;
}

span {
    visibility: hidden;
}


/*====MOBILE====*/
@media only screen and (max-width: 1200px) { 
    .contact-console {
        top: 650vh;
    }
}


/*====MOBILE====*/
@media only screen and (max-width: 950px) {
    .contact-console {
        position: absolute;
        top: 885vh;
    }

    .contact-console {
        height: 150vh;
    }

    form {
        padding: 0;     
    }

    input {
        height: 5vh;
        width: 60vw;
    }
    
    textarea {
        height: 25vh;
        width: 65vw;
    }
}