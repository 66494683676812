/*Position and Formatting*/
.footer-console {
    position: absolute;
    top: 600vh;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 4em;
    z-index: 999;
    margin: 0;
    background-color: black;
    display: flex;
    justify-content: center;
}

.footer-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

/*Icons Styling*/
.social-links {
    color: rgb(233, 233, 233);
    font-size: 2em;
    text-decoration: none;
    padding-left: 20px;
}

.social-links i {
    transition: all 0.2s ease-in-out;
}

.social-links i:hover {
    color: palevioletred;
    text-shadow: 1px 1px 1px rgba(0, 0, 102);
    transform: scale(1.2);
}


/*====MOBILE====*/
@media only screen and (max-width: 1200px) { 
    .footer-console {
        top: 750vh;
    }
}

/*====MOBILE====*/
@media only screen and (max-width: 950px) { /*Smart Phones*/
    .footer-console {
        top: 1030vh;
    }
}