.portfolio-console {
    position: absolute;
    top: 200vh;
    left: 0;
    background-color: rgb(26, 26, 26);
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient( 109.6deg,  rgba(177,173,219,1) 11.2%, rgba(245,226,226,1) 91.1% );
    box-shadow: 0 0 10px rgb(255, 255, 255);
}

.portfolio-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 30px;
    justify-content: center;
    align-items: center;
    color: white;
}

.portfolio-wrapper img {
    width: 100%;
    height: 100%;
    min-width: 630px;
    min-height: 350px;
    max-width: 630px;
    max-height: 350px;
    border-radius: 20px;
    perspective: 1000px;
    perspective-origin: 84% 50%;
    box-shadow: 0 0 2px rgb(68, 68, 68);
    transition: all 1s ease;
  }


  .portfolio-wrapper img:hover {
    transform: scale(1.08);
}



/*====MOBILE====*/
@media only screen and (max-width: 1200px) { 
    .portfolio-console {
        top: 315vh;
        height: 120vh;
    }

    .portfolio-wrapper {
        flex-direction: column;
        row-gap: 50px;
    }
}


@media only screen and (max-width: 950px) { 
    .portfolio-console {
        top: 310vh;
    }

    .portfolio-wrapper img {
        min-width: 370px;
        min-height: 220px;
        max-width: 370px;
        max-height: 220px;
    }
}
