.skills-console {
    position: absolute;
    top: 100vh;
    left: 0;
    height: 100vh;
    width: 100vw;
}

.skills-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-around;
}

.quote {
    text-align: right;
    padding-right: 50px;
}

.quote h2 {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
	font-size: 2em;
    background: -webkit-linear-gradient(-91deg, #EEF85B 5%, #7AEC8D 53%, #09E5C3 91%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}



.skills-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    row-gap: 20px;
}

.skill-card:nth-child(1) {
    box-shadow: 0px 0px 20px rgb(216, 45, 102);
    transition: box-shadow 1s ease;
}

.skill-card:nth-child(1):hover {
    box-shadow: 0px 0px 40px rgb(255, 75, 135);
}

.skill-card:nth-child(2) {
    box-shadow: 0px 0px 20px rgb(163, 0, 228);
    transition: box-shadow 1s ease;
}

.skill-card:nth-child(2):hover {
    box-shadow: 0px 0px 35px rgb(209, 94, 255);
}

.skill-card:nth-child(3) {
    box-shadow: 0px 0px 20px rgb(0, 161, 135);
    transition: box-shadow 1s ease;
}

.skill-card:nth-child(3):hover {
    box-shadow: 0px 0px 35px rgb(0, 255, 213);
}

.skill-card, .skill-card-overlay {
    width: 34em;
    height: 10em;
    color: whitesmoke;
}

.skill-card {
    border-radius: 20px;
    border: 2px solid whitesmoke;
    background-color: rgb(32, 32, 32);
    background-clip: padding-box;
}

.skill-card-overlay {
    position: relative;
}

.skill-card-overlay h2 {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.8em;
    padding-left: 25px;
}

.skill-card-overlay h2 i {
    padding-left: 10px;
}


.skill-card-overlay p {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1em;
    padding: 0px 25px 0px 25px;
}






/*====MOBILE====*/
@media only screen and (max-width: 1200px) { 
    .skills-console {
        height: 200vh;
    }
    
    .skills-container {
        padding-bottom: 40px;
    }

    .skills-wrapper {
        flex-direction: column-reverse;
    }
}


@media only screen and (max-width: 950px) { 

    .quote {
        text-align: left;
        padding-left: 40px;
    }

    .quote h2 {
        font-size: 1.5em;
    }

    .skills-container {
        padding-bottom: 50px;
    }

    .skills-console {
        height: 180vh;
    }

    .skill-card-overlay h2 {
        font-size: 1.4em;
    }

    .skill-card, .skill-card-overlay {
        width: 26em;
        height: 10em;
    }
}



@media only screen and (max-width: 600px) { 
    .skills-console {
        height: 225vh;
    }

    .skill-card, .skill-card-overlay {
        width: 22em;
        height: 12em;
        color: whitesmoke;
    }
}