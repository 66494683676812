/*Console Positioning and Formatting*/
.education-console {
    position: absolute;
    top: 400vh;
    left: 0;
    width: 100vw;
}

.education-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    column-gap: 150px;
}

.education-blurb-1, .education-blurb-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 25px;
    color: white;
}

.education-box {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 450px;
    height: 250px;
    border-radius: 20px;
    border: 2px solid whitesmoke;
    background-color: rgb(32, 32, 32);
    background-clip: padding-box;
}

.education-box {
    font-family: 'Roboto Condensed', sans-serif;
}


.education-box i {
    padding-left: 5px;
}

.education-box h2 {
    font-size: 2.3em;
    font-weight: 700;
    margin-top: 40px;
}

.education-box h3 {
    font-size: 1.2em;
    font-weight: normal;
    margin-top: -10px;
}

.education-box h4 {
    font-size: 1.1em;
    font-weight: bolder;
    margin-top: -10px;
}

.education-box p {
    font-size: 1em;
    margin-top: -10px;
}

.education-box a {
    text-decoration: none;
    color: white;
    cursor: pointer;

}

.box-1 {
    box-shadow: 0px 0px 20px rgb(216, 45, 102);
    transition: box-shadow 1s ease;
}

.box-1:hover {
    box-shadow: 0px 0px 40px rgb(255, 75, 135);
}

.box-1 i {
    color: rgb(216, 45, 102);
}

.box-2 {
    box-shadow: 0px 0px 20px rgb(163, 0, 228);
    transition: box-shadow 1s ease;
}

.box-2:hover {
    box-shadow: 0px 0px 35px rgb(209, 94, 255);
}

.box-2 i {
    color: rgb(209, 94, 255);
}

.box-3 {
    box-shadow: 0px 0px 20px rgb(0, 161, 135);
    transition: box-shadow 1s ease;
}

.box-3:hover {
    box-shadow: 0px 0px 35px rgb(0, 255, 213);
}

.box-3 i {
    color: rgb(0, 161, 135);
}

.box-4 {
    box-shadow: 0px 0px 20px rgb(0, 65, 204);
    transition: box-shadow 1s ease;
}

.box-4:hover {
    box-shadow: 0px 0px 35px rgb(58, 120, 255);
}

.box-4 i {
    color: rgb(58, 120, 255);
}

/*====MOBILE====*/
@media only screen and (max-width: 1200px) { 
    .education-console {
        top: 550vh;
    }
}

@media only screen and (max-width: 950px) { /*Smart Phones*/
    .education-console {
        top: 700vh;
    }

    .education-wrapper {
        flex-direction: column;
        row-gap: 50px;
    }

    .education-blurb-1, .education-blurb-2 {
        flex-direction: column;
    }

    .education-box {
        width: 20em;
        height: 17em;
    }
}